import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import GoodsRecComp from "../../../components/purchase-section/goods-receipt/goods-receipt";
import {
  purchaseItemsGetAction,
  purchaseReturnItemsGetAction,
} from "../../../redux/actions/purchase-action";

const GoodsReceipt = () => {
  const { itemId, type } = useParams();
  const itemIdArray = JSON.parse(decodeURIComponent(itemId));

  const allitems = itemIdArray.map((item) => {
    const { cmr_id, ...rest } = item;
    return rest;
  });
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (type === "return") {
      setLoading(true);
      dispatch(purchaseReturnItemsGetAction(allitems, onSuccPur, onErrPur));
    } else {
      setLoading(true);
      dispatch(purchaseItemsGetAction(allitems, onSuccPur, onErrPur));
    }
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };    

  return (   
    <InnerLayout title="Goods Receipt">
      {loading ? (
        <LoadingSpinner />
      ) : (            
        <GoodsRecComp
          type={type}                          
          orderDetail={orderDetail?.itemData}
          vendorDetails={orderDetail?.vendorDetails}
        />
      )}
    </InnerLayout>
  );
};

export default GoodsReceipt;
