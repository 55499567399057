import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/icons/logo";
import DashboardIcon, {
  CustomerIcon,
  InventoryIcon,
  InvoiceIcon,
  ReportIcon,
  ReturnBillIcon,
  SettingsIcon,
} from "../../assets/icons/sidebar-icons";
import "./layout.css";
import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  CurrencyRupeeIcon,
  ArchiveBoxIcon,
  BuildingStorefrontIcon,
  ComputerDesktopIcon,
  RectangleStackIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
// import sidebarArrow from "../../assets/icons/sidebarArrow.svg";
import successToast from "../helper-components/success-toast";
import SidebarArrow from "../../assets/icons/sidebarArrow";
import { truncateString } from "../helper-components/helper-function";

const Sidebar = ({ isExpanded, setExpanded, permissionFn = () => {} }) => {
  const handleToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  const [openPath, setOpenPath] = useState(null);

  const handleOpen = (itemPath) => {
    setOpenPath(openPath === itemPath ? null : itemPath);
  };
  const role = localStorage?.getItem("user_role");
  const navigate = useNavigate();

  const sidebarData = [
    {
      icon: <DashboardIcon fill="var(--tertiary-color)" />,
      title: "Dashboard",
      path: "/dashboard",
      module_name: "dashboard",
    },
    {
      icon: <CustomerIcon fill="var(--tertiary-color)" />,
      title: "Customers",
      path: "/customer",
      createPath: "/add-customer",
      module_name: "customer",
    },
    {
      icon: <InvoiceIcon fill="var(--tertiary-color)" />,
      title: "Billing",
      path: "/bills",
      createPath: "/add-invoice",
      module_name: "invoice",
    },
    {
      icon: <ReturnBillIcon fill="var(--tertiary-color)" />,
      title: "Return Bill",
      path: "/return-bills",
      module_name: "return_billing",
    },

    // {
    //   icon: <InvoiceIcon />,
    //   title: "Billing",
    //   path: "/invoice",
    //   createPath: "/add-invoice",
    //   module_name: "invoice",
    // },
    {
      icon: <CurrencyRupeeIcon className="text-white h-6 w-6" />,
      title: "Sales",
      path: "/sales",
      module_name: "sales_order",

      subItems: [
        {
          title: "Sales order",
          path: "/sales",
          createPath: "/add-sales/%5B%5D/order",
          module_name: "sales_order",
        },

        {
          title: "Delivery Note",
          path: "/sales-delivery",
          createPath: "/add-delivery/%5B%5D/delivery",
          module_name: "sales_delivery",
        },
        {
          title: "Sales Invoice	",
          path: "/sales-invoice",
          createPath: "/add-sales-invoice/%5B%5D/invoice",
          module_name: "sales_invoice",
        },
        {
          title: "Sales Return	",
          path: "/sales-return",
          // createPath: "/add-sales-return/%5B%5D/return",
          module_name: "sales_return",
        },

        {
          title: "Sales Credit Note",
          path: "/sales-credit-note",
          // createPath: "/add-sales-credit-note/%5B%5D/credit",
          module_name: "sales_credit_note",
        },
        {
          title: "Payment In",
          path: "/sales-payment-in",
          module_name: "payment_in",
          createPath: "/add-sales-payment-in",
        },
      ],
    },

    {
      icon: <ArchiveBoxIcon className="text-white h-6 w-6" />,
      title: "Purchase",
      path: "/purchase",
      module_name: "purchase_order",
      subItems: [
        {
          title: "Purchase Order",
          path: "/purchase",
          createPath: "/add-purchase/%5B%5D/purchase",
          module_name: "purchase_order",
        },
        {
          title: "Good Receipt Note",
          path: "/goods",
          createPath: "/goods-receipt/%5B%5D/goods",
          module_name: "goods_receipt_note",
        },

        {
          title: "Purchase Invoice",
          path: "/purchase-invoice",
          createPath: "/add-purchase-invoice/%5B%5D/invoice",
          module_name: "purchase_invoice",
        },
        {
          title: "Purchase Return",
          path: "/purchase-return",
          createPath: "/add-purchase-return/%5B%5D/invoice",
          module_name: "purchase_return",
        },
        {
          title: "Purchase Credit Note",
          path: "/purchase-credit",
          createPath: "/add-purchase-credit/%5B%5D/credit",
          module_name: "purchase_credit_note",
        },

        {
          title: "Payment Out",
          path: "/purchase-payment-out",
          module_name: "payment_out",
          createPath: "/add-purchase-payment-out",
        },
        {
          title: "Bulk Purchase Order",
          path: "/bulk-purchase-order",
          module_name: "payment_out",
        },
      ],
    },
    {
      icon: <InventoryIcon fill="var(--tertiary-color)" />,
      title: "Inventory",
      path: "/product",
      module_name: "product",
      subItems: [
        {
          title: "Product Info",
          path: "/product",
          createPath: "/add-product",
          module_name: "product",
        },
        {
          title: "Warehouse Info",
          path: "/under-dev/Warehouse Info",
          module_name: "warehouse_info",
        },

        {
          title: "Bin Location",
          path: "/under-dev/Bin Location",
          module_name: "period-volume",
        },

        {
          title: "Batches Info",
          path: "/under-dev/Batches Info",
          module_name: "dis_group",
        },
        {
          title: "Inventory Transfers",
          path: "/inventory",
          module_name: "dis_group",
        },
        {
          title: "Stock Transfers",
          path: "/stock-transfer",
          module_name: "stock_transfer",
          createPath: "/add-stock-transfer",
        },
      ],
    },
    {
      icon: <CustomerIcon fill="var(--tertiary-color)" />,
      title: "Doctors",
      path: "/doctor",
      createPath: "/add-doctor",
      module_name: "doctor",
    },
    {
      icon: <ReportIcon fill="var(--tertiary-color)" />,
      title: "Reports",
      path: "/reports",
      module_name: "reports",
    },
    // {
    //   icon: <DiscountIcon />,
    //   title: "Discounts",
    //   path: "/discounts",
    //   module_name: "discounts",
    //   subItems: [
    //     {
    //       title: "Price List",
    //       path: "/price-list",
    //       module_name: "pricelist",
    //     },
    //     {
    //       title: "Period Discounts",
    //       path: "/period-volume",
    //       module_name: "period_discount",
    //     },
    //     {
    //       title: "Discount Groups",
    //       path: "/discount-group",
    //       module_name: "discount_groups",
    //     },
    //   ],
    // },
    {
      icon: <BuildingStorefrontIcon className="text-white h-6 w-6" />,
      title: "Store Information",
      path: "/store-information",
      module_name: "store-information",
      subItems: [
        {
          title: "Document",
          path: "/store-information",
          createPath: "/add-store",
          module_name: "store_document_process",
        },
        {
          title: "Store Execution",
          path: "/under-dev/Document Execution Process",
          module_name: "store_execution_process",
        },
      ],
    },

    {
      icon: <ComputerDesktopIcon className="text-white h-6 w-6" />,
      title: "Human Resources",
      path: "/human",
      module_name: "humanre",
      subItems: [
        {
          title: "Attendance Management ",
          path: "/under-dev/Attendance Management ",
          module_name: "price-list",
        },
        {
          title: "Shift Management ",
          path: "/under-dev/Shift Management ",
          module_name: "period-volume",
        },
      ],
    },
    // {
    //   icon: <UserCircleIcon className="text-white h-6 w-6" />,
    //   title: "Accounts",
    //   path: "/account",
    //   subItems: [
    //     {
    //       title: "Chart of Accounts",
    //       path: "/chart-of-account",
    //       module_name: "price-list",
    //     },
    //     {
    //       title: "Account Allocation",
    //       path: "/account-allocation",
    //       module_name: "period-volume",
    //     },
    //     {
    //       title: "Journal Entry",
    //       path: "/journal-entry",
    //       module_name: "period-volume",
    //     },
    //   ],
    // },
    {
      icon: <RectangleStackIcon className="text-white h-6 w-6" />,
      title: "Reports",
      path: "/reports",
      module_name: "reports",
      subItems: [
        {
          title: "Sales",
          path: "/under-dev/Sales Reports",
          module_name: "price-list",
          subItems: [
            {
              title: "Sales Report",
              path: "/sales-reports",
              module_name: "price-list",
            },
          ],
        },
        {
          title: "Purchase",
          path: "/fghfcvbvc",
          module_name: "period-volume",
          subItems: [
            {
              title: "Report 1",
              path: "/under-dev/Purchase Report 1",
              module_name: "price-list",
            },
          ],
        },
      ],
    },
  ];

  const settionsData = [
    {
      icon: <SettingsIcon fill="var(--tertiary-color)" />,
      title: "Settings",
      path: "/settings",
      module_name: "setting",
    },
    // {
    //   icon: <CustomerIcon />,
    //   title: "Users",
    //   path: "/user",
    //   createPath: "/add-user",
    //   module_name: "user",
    // },
  ];

  return (
    <div
      className={`h-screen bg-secondary p-1 flex flex-col justify-between hide-scrollbar overflow-y-clip ${
        isExpanded ? "sidebar_container" : "w-20"
      }`}
    >
      <div
        className={`overflow-y-auto p-2 my-3 ${
          !isExpanded
            ? "hide-scrollbar"
            : "scrollbar-thin-class scrollbar-thumb-white scrollbar-track-transparent"
        }`}
      >
        <Link
          to="/"
          className={`text-white flex items-center space-x-4 fw_500 fs_20 ${
            isExpanded ? "p-3" : "justify-center"
          }`}
        >
          {/* <Logo /> */}
          {localStorage?.getItem("store_icon") ? (
            <img
              className="rounded-lg"
              style={{
                width: `${isExpanded ? "4rem" : "3rem"}`,
                height: `${isExpanded ? "4rem" : "3rem"}`,
                // objectFit: "cover",
              }}
              src={localStorage?.getItem("store_icon")}
            />
          ) : (
            <Logo />
          )}

          {isExpanded && (
            <div className="flex flex-col">
              <span
                title={localStorage?.getItem("company") ?? "POS"}
                className="text-sm fw_600"
              >
                {truncateString(localStorage?.getItem("company") ?? "POS", 10)}
              </span>
              <span
                title={localStorage?.getItem("company_landmark") ?? "POS"}
                className="text-xs text-787878 fw_400"
              >
                {truncateString(
                  localStorage?.getItem("company_landmark") ?? "POS",
                  22
                )}
              </span>
            </div>
          )}
        </Link>
        <div
          key={`sidebar-${1}`}
          className="flex-col space-y-6 text-white bg-secondary "
        >
          <List className="bg-secondary gap-0">
            {isExpanded
              ? sidebarData.map((item, index) => {
                  return (
                    <SidebarItem
                      permissionFn={permissionFn}
                      key={`sidebar-${index}`}
                      item={item}
                      handleOpen={handleOpen}
                      isOpen={openPath}
                      index={index}
                    />
                  );
                })
              : sidebarData.map((item, index) => (
                  <>
                    {permissionFn(item?.module_name).read && (
                      <Link key={`sidebar-${index}`} to={item.path}>
                        <ListItem className="bg-secondary gap-4 px-0 justify-center">
                          <ListItemPrefix className="!mr-0">
                            {item.icon}
                          </ListItemPrefix>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ))}
          </List>
        </div>

        <hr className="my-2 border-787878 opacity-50" />
        <div className="flex-col mt-4 text-white bg-secondary">
          <List className="bg-secondary gap-0">
            {isExpanded
              ? settionsData.map((item, index) => {
                  return (
                    <SidebarItem
                      permissionFn={permissionFn}
                      key={`sidebar-${index}`}
                      item={item}
                      handleOpen={handleOpen}
                      isOpen={openPath}
                      index={index}
                    />
                  );
                })
              : settionsData.map((item, index) => (
                  <>
                    {permissionFn(item?.module_name).read ||
                      (role === "admin" && (
                        <Link key={index} to={item.path}>
                          <ListItem className="bg-secondary gap-4 px-0 justify-center">
                            <ListItemPrefix className="!mr-0">
                              {item.icon}
                            </ListItemPrefix>
                          </ListItem>
                        </Link>
                      ))}
                  </>
                ))}
          </List>
        </div>
      </div>
      <div
        className={`flex items-center ${
          isExpanded ? "px-7 p-4 justify-between" : "py-4 justify-center"
        }`}
      >
        {isExpanded && (
          <span
            className="text-white p-2 px-5 cursor-pointer bg-F51D00 rounded-md fs_14 text-center"
            onClick={() => {
              localStorage.clear("accessToken");
              navigate("/login");
              successToast("Logged Out Successfully");
            }}
          >
            Log out
          </span>
        )}
        <button
          onClick={handleToggle}
          className="rounded-full text-primary p-1 "
          id="sidebar-toggle-button"
        >
          <div className="h-6 w-6">
            {isExpanded ? (
              <SidebarArrow />
            ) : (
              // <img src={sidebarArrow} alt="" />
              // <img src={sidebarArrow} alt="" className="rotate-180" />
              <div className="rotate-180">
                {" "}
                <SidebarArrow />{" "}
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;

const SidebarItem = ({
  item,
  depth = 0,
  handleOpen,
  isOpen,
  parent,
  key,
  permissionFn = () => {},
}) => {
  const role = localStorage?.getItem("user_role");

  const hasSubItems = item.subItems && item.subItems.length > 0;

  const isAnyChildOpen = (item) => {
    if (isOpen === item.path) return true;
    if (item.subItems) {
      return item.subItems.some((subItem) => isAnyChildOpen(subItem));
    }
    return false;
  };

  const open = isAnyChildOpen(item);

  const toggleOpen = () => {
    open ? handleOpen(parent ? parent : item.path) : handleOpen(item.path);
  };
  const currentPath = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {hasSubItems &&
      item.subItems?.some((item) => permissionFn(item?.module_name)?.read) ? (
        <Accordion key={key} open={open} className="bg-secondary ">
          <AccordionHeader
            onClick={toggleOpen}
            className="border-b-0 px-2 gap-1 py-3  rounded-md"
          >
            <ListItemPrefix className={`w-[20px] ml-[6px]`}>
              {item.icon}
            </ListItemPrefix>
            <Typography
              color="white"
              className="mr-auto ml-1 font-normal text-base text-nowrap  flex text-sm justify-between items-center w-full"
            >
              {item.title}
              <ChevronDownIcon
                className={`text-white h-5 w-5 transition-transform ${
                  open ? "rotate-180" : ""
                }`}
              />
            </Typography>
          </AccordionHeader>
          <AccordionBody className="py-1 pl-3">
            <List
              style={{
                display: open ? "block" : "none",
              }}
              className="p-0"
            >
              {item.subItems.map((subItem, i) => (
                <SidebarItem
                  permissionFn={permissionFn}
                  key={i || item.path}
                  item={subItem}
                  depth={depth + 1}
                  handleOpen={handleOpen}
                  isOpen={isOpen}
                  parent={item.path}
                />
              ))}
            </List>
          </AccordionBody>
        </Accordion>
      ) : (
        <Link
          key={key}
          to={item.path}
          className={`my-2 py-1 rounded-md flex justify-start  text-white hover:bg-212d46 ${
            currentPath?.pathname === item.path ? "bg-primary" : "bg-secondary"
          } 
          ${hasSubItems && "hidden"}
          ${
            permissionFn(item?.module_name).read ||
            (role === "admin" && item?.module_name === "setting" ? true : false)
              ? ""
              : "hidden"
          }
           `}
        >
          <ListItem
            className={`gap-1 flex  items-center group ${
              item.icon ? "px-2" : "px-2"
            } py-1`}
          >
            <ListItemPrefix className="ml-2">{item.icon}</ListItemPrefix>
            <Typography
              color="white"
              className="mr-auto font-normal text-nowrap text-sm w-full flex gap-1 justify-between items-center p-0"
              style={{ paddingLeft: `${depth * 8}px` }}
            >
              {item.title}
              {item?.createPath && (
                <span
                  className={`flex justify-center rounded-md ${
                    currentPath?.pathname === item.path ||
                    currentPath?.pathname === item.createPath
                      ? ""
                      : "hidden"
                  } group-hover:flex justify-center hover:bg-212d46 `}
                  style={{
                    width: "29px",
                  }}
                >
                  <PlusCircleIcon
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(item?.createPath);
                    }}
                    className={`w-6 text-white`}
                  />
                </span>
              )}
            </Typography>
          </ListItem>
        </Link>
      )}
    </>
  );
};
