import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputField from "../../components/forms/input-field";
import "../../components/customer-information/customer-information.css";
import TextAreaField from "../../components/forms/textarea-field";
import SelectField from "../../components/forms/select-field";
import { useDispatch } from "react-redux";
import {
  customerCreateAction,
  customerDeleteAction,
  customerEditAction,
} from "../../redux/actions/customer-action";
import AddButton from "../../components/buttons/button";
import { pincodeListAction } from "../../redux/actions/general-action";
import successToast from "../../components/helper-components/success-toast";
import CheckBox from "../../components/forms/check-box-field";
import errorToast from "../../components/helper-components/error-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customerTypeoptions } from "../helper-components/data";

const CustomerViewEdit = ({ customer, disabled = false, page = "add" }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phoneNumber") || "";
  const [cusList, setCusList] = useState([]);
  const navigate = useNavigate();
  const initialValues = {
    country_code: "+91",
    cmr_phone_number: customer?.cmr_phone_number
      ? customer?.cmr_phone_number
      : phoneNumber
      ? phoneNumber
      : "",
    cmr_first_name: customer?.cmr_first_name ?? "",
    cmr_type: customer?.cmr_type ?? "individual",
    cmr_last_name: customer?.cmr_last_name ?? "",
    cmr_email: customer?.cmr_email ?? "",
    cmr_gstin: customer?.cmr_gstin ?? "",
    cmr_pan: customer?.cmr_pan ?? "",
    cmr_company: customer?.cmr_company ?? "",
    cmr_street1: customer?.cmr_street1 ?? null,
    cmr_street2: customer?.cmr_street2 ?? null,
    cmr_city: customer?.cmr_city ?? "",
    cmr_state: customer?.cmr_state ?? "",
    cmr_pincode: customer?.cmr_pincode ?? "",
    is_ecommerce_cmr: customer?.is_ecommerce_cmr ?? false,
    is_vendor_cmr: customer?.is_vendor_cmr ?? false,
    is_corporate_cmr: customer?.is_corporate_cmr ?? false,
    is_store_cmr: customer?.is_store_cmr ?? false,
    inter_company_sync: customer?.inter_company_sync ?? false,
  };
  const validationSchema = Yup.object().shape({
    country_code: Yup.string(),
    cmr_type: Yup.string().required("Type is required"),
    cmr_pan: Yup.string()
      .nullable()
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN number")
      .test(
        "either-pan-or-gstin",
        "Either PAN or GSTIN is required for business",
        function (value) {
          const { cmr_type, cmr_gstin } = this.parent;
          if (cmr_type === "business" && !value && !cmr_gstin) {
            return false;
          }
          return true;
        }
      ),
    cmr_phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be 10 digits"),
    cmr_first_name: Yup.string().test(
      "required-if-individual",
      "Name is required",
      function (value) {
        const { cmr_type, is_corporate_cmr } = this.parent;
        if (cmr_type === "individual" && is_corporate_cmr && !value) {
          return false;
        }
        return true;
      }
    ),

    cmr_last_name: Yup.string(),
    cmr_email: Yup.string()
      .email("Invalid email")
      .test("required-if-individual", "Email is required", function (value) {
        const { cmr_type, is_corporate_cmr } = this.parent;
        if (cmr_type === "individual" && is_corporate_cmr && !value) {
          return false;
        }
        return true;
      }),
    cmr_company: Yup.string().test(
      "required-if-business-or-individual-corporate",
      "Company is required",
      function (value) {
        const { cmr_type, is_corporate_cmr } = this.parent;
        if (
          (cmr_type === "business" ||
            (cmr_type === "individual" && is_corporate_cmr)) &&
          !value
        ) {
          return false;
        }
        return true;
      }
    ),
    cmr_street1: Yup.string().nullable(),
    cmr_street2: Yup.string().nullable(),
    cmr_city: Yup.string(),
    cmr_state: Yup.string(),
    cmr_pincode: Yup.string().matches(
      /^\d{6}$/,
      "Pincode must be exactly 6 digits"
    ),
    cmr_gstin: Yup.string()
      .nullable()
      .matches(
        /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})?$/,
        "Invalid GST number"
      )
      .test(
        "either-gstin-or-pan",
        "Either GSTIN or PAN is required for business",
        function (value) {
          const { cmr_type, cmr_pan } = this.parent;
          if (cmr_type === "business" && !value && !cmr_pan) {
            return false;
          }
          return true;
        }
      ),
  });

  const onSubmit = (values, { resetForm }) => {
    let actionType = customerCreateAction;
    if (page === "edit") {
      actionType = customerEditAction;
    }
    dispatch(
      actionType(
        {
          cmr_id: customer?.cmr_id,
          cmr_type: values?.cmr_type,
          cmr_phone_number: values?.cmr_phone_number,
          cmr_first_name: values?.cmr_first_name,
          cmr_last_name: values?.cmr_last_name,
          cmr_email: values?.cmr_email,
          cmr_company: values?.cmr_company,
          cmr_street1: values?.cmr_street1,
          cmr_street2: values?.cmr_street2,
          cmr_city: values?.cmr_city,
          cmr_gstin: values?.cmr_gstin,
          cmr_pan: values?.cmr_pan,
          cmr_state: values?.cmr_state,
          cmr_pincode: values?.cmr_pincode,
          is_ecommerce_cmr: values?.is_ecommerce_cmr,
          is_vendor_cmr: values?.is_vendor_cmr,
          is_corporate_cmr: values?.is_corporate_cmr,
          is_store_cmr: values?.is_store_cmr,
          inter_company_sync: values?.inter_company_sync,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    if (phoneNumber) {
      navigate(
        `/add-invoice?phoneNumber=${data?.data?.cmr_phone_number}&id=${data?.data?.cmr_id}`
      );
    } else {
      navigate("/customer");
    }
    successToast("Customer created Successfully");
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };
  const handleVoidAction = (id) => {
    dispatch(
      customerDeleteAction(
        {
          cmr_id: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    navigate("/customer");
  };
  const onErrorDel = () => {};

  function fetchAddressFn(inputValue, setFieldValue) {
    if (inputValue?.length === 6) {
      dispatch(
        pincodeListAction(
          inputValue,
          (response) => {
            if (response?.[0]?.Status === "Error") {
            } else {
              const firstPostOffice = response?.[0]?.PostOffice?.[0];
              setFieldValue("cmr_state", firstPostOffice?.State);
              setFieldValue("cmr_city", firstPostOffice?.District);
            }
          },
          (data) => {
            setFieldValue("cmr_state", "");
            setFieldValue("cmr_city", "");
          }
        )
      );
    } else {
      setFieldValue("cmr_state", "");
      setFieldValue("cmr_city", "");
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, setValues }) => {
        return (
          <Form className="h-full pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div class="flex flex-wrap">
                <div class="w-full lg:w-1/2 xl:w-[40%] px-9">
                  <SelectField
                    handleSelected={(sel) => {
                      setValues({ ...initialValues, cmr_type: sel?.value });
                    }}
                    isDisabled={disabled || page === "edit"}
                    placeholder={"Select"}
                    options={customerTypeoptions}
                    label={"Type"}
                    id={"cmr_type"}
                    name="cmr_type"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    labelWidth="6.5rem"
                  />
                  <InputField
                    maxLength={10}
                    isDisabled={disabled || page === "edit"}
                    autoFocus={true}
                    containerClass="mb-2 position-relative w-full"
                    type="number"
                    name="cmr_phone_number"
                    placeholder="Enter customer phone number"
                    label="Phone"
                    isRequired={true}
                    labelWidth="6.5rem"
                  />
                  <InputField
                    isRequired={
                      values?.cmr_type === "individual" &&
                      values?.is_corporate_cmr
                    }
                    isDisabled={disabled}
                    containerClass="mb-2 position-relative w-full"
                    type="text"
                    name="cmr_first_name"
                    placeholder="Enter name"
                    label="Name"
                    labelWidth="6.5rem"
                  />
                  <InputField
                    onKeyDown={() => {}}
                    isRequired={
                      values?.cmr_type === "individual" &&
                      values?.is_corporate_cmr
                    }
                    isDisabled={disabled}
                    type="email"
                    name="cmr_email"
                    placeholder="Enter E-mail"
                    label="Email"
                    labelWidth="6.5rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="cmr_company"
                    placeholder="Enter company name"
                    label="Company"
                    isRequired={
                      values?.cmr_type === "business" ||
                      (values?.cmr_type === "individual" &&
                        values?.is_corporate_cmr)
                    }
                    labelWidth="6.5rem"
                  />

                  {values?.cmr_type === "business" && (
                    <>
                      <InputField
                        isRequired={errors?.cmr_gstin}
                        type="text"
                        name="cmr_gstin"
                        placeholder="xxxxxxxxxxxxxxx"
                        label="GSTIN"
                        labelWidth="6.5rem"
                      />
                      <InputField
                        isRequired={errors?.cmr_pan}
                        type="text"
                        name="cmr_pan"
                        placeholder="xxxxxxxxxxx"
                        label="PAN"
                        labelWidth="6.5rem"
                      />
                    </>
                  )}
                  {/* <div className="ml-[6.5rem]">
                    <CheckBox
                      conClass="flex flex-wrap gap-3 mb-5"
                      isDisabled={disabled}
                      items={[
                        {
                          label: "Inter Company Vendor",
                          name: "inter_company_sync",
                          isRequired: false,
                        },
                      ]}
                      labelWidth
                    />
                  </div> */}
                </div>
                <div class="w-full lg:w-1/2 xl:w-[40%] px-9">
                  <InputField
                    type="text"
                    labelWidth="6.5rem"
                    isDisabled={disabled}
                    name="cmr_pincode"
                    placeholder="Enter pincode"
                    label="Pin code"
                    isRequired={false}
                    handleChange={(e) => {
                      fetchAddressFn(e?.target?.value, setFieldValue);
                    }}
                  />
                  <>
                    <InputField
                      labelWidth="6.5rem"
                      isDisabled={true}
                      type="text"
                      name="cmr_state"
                      placeholder="state"
                      label="State"
                      isRequired={false}
                    />
                    <InputField
                      labelWidth="6.5rem"
                      isDisabled={true}
                      type="text"
                      name="cmr_city"
                      placeholder="Enter city"
                      label="City"
                      isRequired={false}
                    />

                    <TextAreaField
                      labelWidth="6.5rem"
                      isDisabled={disabled}
                      label="Address line 1"
                      placeholder="Enter address 1"
                      name="cmr_street1"
                      id={"cmr_street1"}
                      isRequired={false}
                    />

                    {values?.cmr_type === "business" && (
                      <TextAreaField
                        labelWidth="6.5rem"
                        isDisabled={disabled}
                        label="Address line 2"
                        placeholder="Enter address 2"
                        name="cmr_street2"
                        id={"cmr_street2"}
                      />
                    )}
                    <div className="ml-[6.5rem]">
                      {" "}
                      <CheckBox
                        conClass="flex flex-wrap gap-3 mb-5"
                        isDisabled={disabled}
                        items={items}
                      />
                    </div>
                  </>
                </div>
                <div class="w-full lg:w-1/2 xl:w-[20%] px-9"></div>
              </div>
              <div
                style={{
                  bottom: "0",
                }}
                className="flex justify-between px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
              >
                {!disabled && (
                  <>
                    {page !== "add" ? (
                      <div></div>
                    ) : (
                      // <DeleteButton
                      //   onclkFn={() => handleVoidAction(customer?.cmr_id)}
                      //   prefixIcon={false}
                      //   type="button"
                      // />
                      <div></div>
                    )}{" "}
                    <AddButton prefixIcon={false} text="Save" type="submit" />
                  </>
                )}

                {page === "view" && (
                  <>
                    {" "}
                    <div></div>
                    <AddButton
                      prefixIcon={false}
                      text="Edit"
                      type="button"
                      onclkFn={() =>
                        navigate(`/edit-customer/${customer?.cmr_id}`)
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CustomerViewEdit;
const items = [
  { label: "E-commerce", name: "is_ecommerce_cmr", isRequired: false },
  { label: "Vendor", name: "is_vendor_cmr", isRequired: false },
  { label: "Corporate", name: "is_corporate_cmr", isRequired: false },
  { label: "Store", name: "is_store_cmr", isRequired: false },
  {
    label: "Inter Company Vendor",
    name: "inter_company_sync",
    isRequired: false,
  },
];
