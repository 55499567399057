import React from "react";

const UploadIcon = ({ fill = "#277DFE", width = "15px", height = "15px" }) => {
  return (
    <div
      style={{
        width: { width },
        height: { height },
      }}
    >
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 10.577V1.927L4.67 4.257L3.962 3.538L7.5 0L11.038 3.538L10.331 4.258L8 1.927V10.577H7ZM2.115 14C1.655 14 1.271 13.846 0.963 13.538C0.654333 13.2293 0.5 12.845 0.5 12.385V9.962H1.5V12.385C1.5 12.5383 1.564 12.6793 1.692 12.808C1.82067 12.936 1.96167 13 2.115 13H12.885C13.0383 13 13.1793 12.936 13.308 12.808C13.436 12.6793 13.5 12.5383 13.5 12.385V9.962H14.5V12.385C14.5 12.845 14.346 13.229 14.038 13.537C13.7293 13.8457 13.345 14 12.885 14H2.115Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default UploadIcon;
