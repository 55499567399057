import React, { useState } from "react";
import Pagination from "../../tables/pagination";
import "./price-list-table.css";
import UpdateDisModal from "../../modals/discounts-modals/update-discount-modal";
import AddButton from "../../buttons/button";
import { useHotkeys } from "react-hotkeys-hook";
const PriceListItemTable = ({
  paginationProps,
  priceListData,
  setReload,
  reload,
}) => {
  const [checkedId, setCheckedId] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // function UpdatePriceFn(item) {
  //   setIsModalOpen(true);
  //   setItem_data(item);
  // }
  function checkboxChecker(e, all) {
    if (all === "all" && checkedId.length === e.length) {
      setCheckedId([]);
    } else if (all === "all") {
      setCheckedId(e);
    } else {
      if (checkedId.includes(e?.target?.id)) {
        const checkedVAlues = [...checkedId];
        const findIndex = checkedVAlues.findIndex(
          (item) => item === e.target?.id
        );
        checkedVAlues.splice(findIndex, 1);
        setCheckedId(checkedVAlues);
      } else {
        setCheckedId((previous) => {
          return [...previous, e.target.id];
        });
      }
    }
  }

  useHotkeys("escape", () => {
    setIsModalOpen(false);
  });
  return (
    <>
      <UpdateDisModal
      checkedId={checkedId}
        setReload={setReload}
        reload={reload}
        priceListData={priceListData}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
      <div className="px-5 xl:px-3 mt-3">
        {checkedId?.length > 0 && (
          <AddButton
            onclkFn={() => setIsModalOpen(true)}
            prefixIcon={false}
            text="Update Items"
            type="button"
          />
        )}
      </div>

      <div className="flex flex-col justify-between h-full">
        <div className="price-tab-container">
          <table className="price-my-tab h-full">
            <thead>
              <tr>
                <th
                  className={`${"price-tab-header"} whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                >
                  <input
                    checked={
                      checkedId?.length ===
                        priceListData?.itemslist?.map((item) => item?.item_id)
                          .length && checkedId.length > 0
                    }
                    onChange={() =>
                      checkboxChecker(
                        priceListData?.itemslist?.map((item) => item?.item_id),
                        "all"
                      )
                    }
                    style={{ transform: "scale(1.1)" }}
                    type="checkbox"
                    id="myCheckbox"
                    className="custom-checkbox form-check-input"
                  />
                </th>
                {tableHeading.map((heading, index) => (
                  <th
                    key={index}
                    className={`${"price-tab-header"} whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                  >
                    {heading?.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full">
              {priceListData?.itemslist?.length > 0 ? (
                <>
                  {priceListData?.itemslist?.map((rowData, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="text-sm tab-cell fw_400 text-secondary">
                        <input
                          checked={checkedId.includes(rowData?.item_id)}
                          id={rowData?.item_id}
                          onChange={(e) => checkboxChecker(e)}
                          style={{ transform: "scale(1.2)" }}
                          type="checkbox"
                          className="custom-checkbox form-check-input"
                        />
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_code}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_name ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_batch_number ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.default_base_price_list ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_base_price ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.default_factor}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_batch_final_purchase_rate ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.margin_factor ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_selling_price}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.discount_percentage ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.margin_percentage ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.is_manual ? "Yes" : "No"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_inventory_uom ?? "--"}
                      </td>
                      <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        {rowData?.item_pricing_uom ?? "--"}
                      </td>
                      {/* <td
                        className={`price-tab-cell text-sm fw_400 text-secondary whitespace-nowrap`}
                      >
                        <button
                          onClick={() => UpdatePriceFn(rowData)}
                          type="button"
                          className="text-primary cursor-pointer"
                        >
                          Edit
                        </button>
                      </td> */}
                    </tr>
                  ))}
                </>
              ) : (
                <tr
                  style={{
                    height: "20rem",
                  }}
                >
                  <td
                    colSpan={tableHeading.length + 1}
                    className="text-center text-primary"
                  >
                    No Items found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination paginationProps={paginationProps} />
      </div>
    </>
  );
};

export default PriceListItemTable;
const tableHeading = [
  {
    label: "Item Code",
  },
  {
    label: "Product Name",
  },
  {
    label: "Batch Number",
  },
  {
    label: "Base Price List",
  },
  {
    label: "Base Price",
  },
  {
    label: "% related to base pice",
  },
  {
    label: "Purchase Rate",
  },
  {
    label: "% related to Purchase Price",
  },
  {
    label: "Selling Price",
  },
  {
    label: "Discount %",
  },
  {
    label: "Margin %",
  },
  {
    label: "Is Manual",
  },
  {
    label: "Inventory UOM",
  },
  {
    label: "Pricing UOM",
  },
  // {
  //   label: "Action",
  // },
];
