import { useEffect } from "react";
import errorToast from "./error-toast";
import FileSaver from "file-saver";
import { purchaseProductListAction } from "../../redux/actions/purchase-action";
import successToast from "./success-toast";
import { transactionPrintAction } from "../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import { isAdmin } from "../../redux/config";

export function formatString(str) {
  // Split the string by underscore and capitalize each word
  const words = str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  // Join the words with space
  return words.join(" ");
}

// const useBarcodeScanner = (onBarcodeScanned) => {
//   useEffect(() => {
//     let barcodeBuffer = ""; // Buffer to accumulate scanned characters
//     let lastKeyPressTime = 0; // Timestamp of the last key press
//     let isScanning = false; // Flag to indicate whether we are scanning

//     const handleBarcodeScan = (event) => {
//       const currentTime = new Date().getTime();
//       const scannedCharacter = event.key;
//

//       if (
//         scannedCharacter === "Shift" ||
//         event.ctrlKey ||
//         event.altKey ||
//         event.metaKey ||
//         scannedCharacter === "Enter"
//       ) {
//         // Ignore special keys like Shift, Ctrl, Alt, Meta (Command on Mac), and Enter key
//         return;
//       }

//       if (currentTime - lastKeyPressTime > 50) {
//         // If the time between key presses is greater than 50 milliseconds, assume it's scanner input
//         barcodeBuffer = scannedCharacter; // Start a new barcode sequence
//         isScanning = true; // Set scanning flag to true
//       } else {
//         barcodeBuffer += scannedCharacter; // Continue accumulating characters
//       }

//       lastKeyPressTime = currentTime; // Update the timestamp
//     };

//     document.addEventListener("keydown", handleBarcodeScan);

//     const handleEnterKey = (event) => {
//       if (event.key === "Enter" && isScanning) {
//         event.preventDefault(); // Prevent the default behavior of the Enter key
//         onBarcodeScanned(barcodeBuffer); // Call the callback when Enter key is pressed
//         barcodeBuffer = ""; // Clear the buffer
//         isScanning = false; // Reset scanning flag
//       }
//     };

//     document.addEventListener("keydown", handleEnterKey);

//     return () => {
//       document.removeEventListener("keydown", handleBarcodeScan);
//       document.removeEventListener("keydown", handleEnterKey);
//     };
//   }, [onBarcodeScanned]);
// };

// export default useBarcodeScanner;

// const useBarcodeScanner = (onBarcodeScanned) => {
//   useEffect(() => {
//     let barcodeBuffer = "";
//     let lastKeyPressTime = 0;
//     let isScanning = false;
//     const expectedBarcodeLength = 2;
//     const barcodeScanThreshold = 1000; // Adjust as needed

//     const handleBarcodeScan = (event) => {

//       const currentTime = new Date().getTime();
//       const scannedCharacter = event.key;

//       if (
//         scannedCharacter === "Shift" ||
//         event.ctrlKey ||
//         event.altKey ||
//         event.metaKey ||
//         scannedCharacter === "Enter"
//       ) {
//         return;
//       }

//       if (currentTime - lastKeyPressTime < barcodeScanThreshold) {
//         barcodeBuffer = scannedCharacter;
//         isScanning = true;
//       } else {
//         barcodeBuffer += scannedCharacter;
//       }
//       lastKeyPressTime = currentTime;
//     };
//     const handleEnterKey = (event) => {
//       if (event.key === "Enter" && isScanning) {
//         event.preventDefault();
//         if (barcodeBuffer.length >= expectedBarcodeLength) {
//           onBarcodeScanned(barcodeBuffer);
//         }
//         barcodeBuffer = "";
//         isScanning = false;
//       }
//     };

//     document.addEventListener("keydown", handleBarcodeScan);
//     document.addEventListener("keydown", handleEnterKey);

//     return () => {
//       document.removeEventListener("keydown", handleBarcodeScan);
//       document.removeEventListener("keydown", handleEnterKey);
//     };
//   }, [onBarcodeScanned]);
// };

// export default useBarcodeScanner;

const useBarcodeScanner = (onBarcodeScanned) => {
  useEffect(() => {
    let barcodeBuffer = "";
    let lastKeyPressTime = 0;
    let isScanning = false;
    const barcodeScanThreshold = 100; // Adjust as needed
    const expectedBarcodeLength = 2; // Set based on expected barcode length

    const handleBarcodeScan = (event) => {
      const currentTime = new Date().getTime();
      const scannedCharacter = event.key;
      if (
        scannedCharacter.length !== 1 || // This filters out special keys like ArrowDown, Enter, etc.
        scannedCharacter === "Shift" ||
        event.ctrlKey ||
        event.altKey ||
        event.metaKey ||
        scannedCharacter === "Enter"
      ) {
        return;
      }

      // Check if the time between key presses is below the threshold
      if (currentTime - lastKeyPressTime < barcodeScanThreshold) {
        barcodeBuffer += scannedCharacter;
        isScanning = true; // Set scanning to true if threshold met
      } else {
        // Start a new scan if this is the first character or outside threshold
        barcodeBuffer = scannedCharacter;
        isScanning = false;
      }
      lastKeyPressTime = currentTime;
    };

    const handleEnterKey = (event) => {
      if (event.key === "Enter" && isScanning) {
        event.preventDefault();
        if (barcodeBuffer.length >= expectedBarcodeLength) {
          onBarcodeScanned(barcodeBuffer);
        }
        barcodeBuffer = "";
        isScanning = false;
      }
    };

    document.addEventListener("keydown", handleBarcodeScan);
    document.addEventListener("keydown", handleEnterKey);

    return () => {
      document.removeEventListener("keydown", handleBarcodeScan);
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, [onBarcodeScanned]);
};

export default useBarcodeScanner;

export const formatYAxisTick = (tick) => {
  if (tick >= 100000) {
    return `${(tick / 100000).toFixed(0)}L`;
  } else if (tick >= 1000) {
    return `${(tick / 1000).toFixed(0)}K`;
  } else {
    return tick;
  }
};

export const handleShowErrorToast = (errors) => {
  const firstErrorField = Object.values(errors)[0]; // Get the first field with an error
  if (firstErrorField) {
    errorToast(firstErrorField + " !");
  }
};

export const hexToRgba = (hex, opacity) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const downloadTemplate = () => {
  const csvContent =
    "item_name,item_sheet_uom,item_quantity,item_unit_price,item_total_price\n";
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  FileSaver.saveAs(blob, "PO_Template.csv");
};

export const fetchData = async (
  inputValue,
  callback,
  field,
  values,
  dispatch
) => {
  const onSuccess = (data) => {
    const options =
      data?.data === null
        ? [
            {
              value: { text: "new_item", itemText: inputValue },
              label: <span className="text-primary">+ New Item</span>,
            },
          ]
        : [
            ...data.data.map((item) => ({
              value: item.item_id,
              label: item[field],
              ...item,
            })),
          ];
    callback(options);
  };

  const onError = (error) => {
    callback([
      {
        value: { text: "new_item", itemText: inputValue },
        label: <span className="text-primary">+ New Item</span>,
      },
    ]);
  };
  if (inputValue?.length > 0) {
    dispatch(
      purchaseProductListAction(
        {
          field: field,
          value: inputValue,
        },
        onSuccess,
        onError
      )
    );
  }
};

export const getBackgroundColor = (isActive, color = "--primary") => {
  const primaryHex = getComputedStyle(document.documentElement)
    .getPropertyValue(color)
    .trim();
  return isActive ? hexToRgba(primaryHex, 0.15) : "";
};

// export const preprocessData = (data) => {
//   return data.map((item) => {
//     const processedItem = {};

//     for (const key in item) {
//       let processedValue = item[key] === null ? "--" : item[key];
//       if (
//         typeof processedValue === "string" &&
//         processedValue.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) &&
//         !isNaN(Date.parse(processedValue))
//       ) {
//         processedValue = new Date(processedValue).toLocaleString();
//       }
//       const formattedKey = key.replace(/_/g, " ").toUpperCase();
//       processedItem[formattedKey] = processedValue;
//     }
//     return processedItem;
//   });
// };
export const preprocessData = (data) => {
  return data.map((item) => {
    const processedItem = {};

    for (const key in item) {
      let processedValue = item[key] === null ? "--" : item[key];
      if (
        typeof processedValue === "string" &&
        processedValue.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) &&
        !isNaN(Date.parse(processedValue))
      ) {
        processedValue = new Date(processedValue).toLocaleString();
      }
      // Simply keep the original key without formatting
      processedItem[key] = processedValue;
    }
    return processedItem;
  });
};

export const transaction_type = [
  { value: "Billing", label: "Billing" },
  { value: "invoice_credit_note", label: "Invoice Credit Note" },
];

export const positiveIntegerFn = (event) => {
  if (event.key === "-" || event.key === "e" || event.key === ".") {
    event.preventDefault();
  }
};

export const decimalNumberFn = (event) => {
  const inputValue = event.target.value;
  if (event.key === "-" || event.key === "e") {
    event.preventDefault();
  }
  if (event.key === "." && inputValue.includes(".")) {
    event.preventDefault();
  }
};

export const capitalizeWords = (str) => {
  return str?.toLowerCase()?.replace(/\b\w/g, (c) => c.toUpperCase());
};

export const parseStringToNumber = (value) => {
  if (typeof value === "string") {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? value : parsedValue; // Return the parsed number if valid, otherwise return the original value
  }
  return value;
};

export function truncateString(str, length) {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  }
  return str;
}

export const handleElectronPrint = (htmlString) => {
  if (window.electron && window.electron.ipcRenderer) {
    window.electron.ipcRenderer.send("print-html", htmlString);
    successToast("Print Successful");
  } else {
  }
};
export const displayHtmlContent = (htmlContent) => {
  const newWindow = window.open("", "_blank");
  newWindow.document.write(htmlContent);
  newWindow.document.close();
  newWindow.print();
};

export function handlea4Print(id, type, dispatch) {
  dispatch(
    transactionPrintAction(
      { transaction_id: id, transaction_type: type },
      (response) => {
        if (isAdmin) {
          displayHtmlContent(response.data);
        } else {
          handleElectronPrint(response.data);
        }
      },
      (response) => {
        // errorToast(response?.data?.message);
      }
    )
  );
}
