import { api } from "./api";

export const purchaseCreateApi = (params) => {
  return api.post(`/purchaseorder/add`, JSON.stringify(params));
};
export const purchaseEditApi = (params) => {
  return api.post(`/purchaseorder/update`, JSON.stringify(params));
};
export const purchaseTableApi = (params) => {
  return api.get(
    `/purchaseorder/orderList/getAll?pageNumber=${
      params?.pageNumber
    }&pageSize=${params?.pageSize}&sortBy=${
      params?.filterData?.sort_by
    }&sortOrder=${params?.filterData?.sort_order}&fromDate=${
      params?.filterData?.from
    }&toDate=${params?.filterData?.to}&orderStatus=${
      params?.filterData?.orderStatus
    }${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};
export const purchaseProductListApi = (params) => {
  return api.get(
    `/purchaseorder/products/get?field=${params?.field}&value=${params?.value}`
  );
};
export const purchaseViewApi = (params) => {
  return api.get(
    `/purchaseorder/getOrderById?pot_id=${params}`,
    JSON.stringify(params)
  );
};
export const purchaseVoidApi = (params) => {
  return api.post(`/purchaseorder/void`, JSON.stringify(params));
};

export const purchaseItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(`/purchaseorder/getPurchaseitemsList?query=${encodedQuery}`);
};

export const goodsReceiptCreateApi = (params) => {
  return api.post(`/purchaseorder/gop/add`, JSON.stringify(params));
};

export const goodsItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(`/purchaseorder/gop/getGopitemsList?query=${encodedQuery}`);
};

export const goodsTableApi = (params) => {
  return api.get(
    `/purchaseorder/gop/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&orderStatus=${params?.filterData?.orderStatus}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const goodsViewApi = (params) => {
  return api.get(
    `/purchaseorder/gop/getOrderById?gort_id=${params}`,
    JSON.stringify(params)
  );
};

export const goodsEditApi = (params) => {
  return api.post(`/purchaseorder/gop/update`, JSON.stringify(params));
};
export const goodsVoidApi = (params) => {
  return api.post(`/purchaseorder/gop/void`, JSON.stringify(params));
};

export const purchaseInvoiceReceiptCreateApi = (params) => {
  return api.post(`/purchaseorder/invoice/add`, JSON.stringify(params));
};
export const purchaseInvoiceTableApi = (params) => {
  return api.get(
    `/purchaseorder/invoice/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&orderStatus=${params?.filterData?.orderStatus}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const purchaseInvoiceViewApi = (params) => {
  return api.get(
    `/purchaseorder/invoice/getOrderById?poit_id=${params}`,
    JSON.stringify(params)
  );
};
export const purchaseInvoiceEditApi = (params) => {
  return api.post(`/purchaseorder/invoice/update`, JSON.stringify(params));
};
export const purchaseInvoiceVoidApi = (params) => {
  return api.post(`/purchaseorder/invoice/void`, JSON.stringify(params));
};
export const purchaseInvoiceItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(
    `/purchaseorder/invoice/getinvoiceitemsList?query=${encodedQuery}`
  );
};

export const purchaseReturnReceiptCreateApi = (params) => {
  return api.post(`/purchaseorder/return/add`, JSON.stringify(params));
};
export const purchaseReturnTableApi = (params) => {
  return api.get(
    `/purchaseorder/return/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&orderStatus=${params?.filterData?.orderStatus}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const purchaseReturnViewApi = (params) => {
  return api.get(
    `/purchaseorder/return/getOrderById?prt_id=${params}`,
    JSON.stringify(params)
  );
};
export const purchaseReturnVoidApi = (params) => {
  return api.post(`/purchaseorder/return/void`, JSON.stringify(params));
};
export const purchaseReturnEditApi = (params) => {
  return api.post(`/purchaseorder/return/update`, JSON.stringify(params));
};
export const purchaseReturnItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(
    `/purchaseorder/return/getreturnitemsList?query=${encodedQuery}`
  );
};

export const purchaseCreditReceiptCreateApi = (params) => {
  return api.post(`/purchaseorder/creditNote/add`, JSON.stringify(params));
};
export const purchaseCreditTableApi = (params) => {
  return api.get(
    `/purchaseorder/creditNote/getAll?pageNumber=${
      params?.pageNumber
    }&pageSize=${params?.pageSize}&sortBy=${
      params?.filterData?.sort_by
    }&sortOrder=${params?.filterData?.sort_order}&fromDate=${
      params?.filterData?.from
    }&toDate=${params?.filterData?.to}&orderStatus=${
      params?.filterData?.orderStatus
    }${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const purchaseCreditViewApi = (params) => {
  return api.get(
    `/purchaseorder/creditNote/getOrderById?pct_id=${params}`,
    JSON.stringify(params)
  );
};
export const purchaseCreditEditApi = (params) => {
  return api.post(`/purchaseorder/creditNote/update`, JSON.stringify(params));
};
export const purchaseCreditVoidApi = (params) => {
  return api.post(`/purchaseorder/creditNote/void`, JSON.stringify(params));
};

export const purchasePaymentOutCusApi = (params) => {
  return api.get(
    `/purchaseorder/invoice/orderList/search?field=${params?.field}&value=${params?.value}`,
    JSON.stringify(params)
  );
};

export const paymentOutTableApi = (params) => {
  return api.get(
    `/paymentOut/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const paymentOutCreateApi = (params) => {
  return api.post(`/paymentOut/add`, JSON.stringify(params));
};

export const paymentOutViewApi = (params) => {
  return api.get(`/paymentOut/getbyId?pot_id=${params}`);
};

export const loadMinQtyItemsApi = (params) => {
  return api.get(
    `/product/minimumstockquntity/get?group_by=${params?.group_by}&filterByVendor=${params?.vendor}&filterByItemgroup=${params?.item_group}&filterByManufacturer=${params?.manufacturer}&filterByItemtype=${params?.item_type}&filterByItemcategory=${params?.item_category}`
  );
};
export const bulkPurchaseCreateApi = (params) => {
  return api.post(`/purchaseorder/bulkOrder/add`, JSON.stringify(params));
};
export const uploadPoCsvApi = (params) => {
  return api.post(`/purchaseorder/import/items`, JSON.stringify(params));
};

export const purchaseTaxCalApi = (params) => {
  return api.get(
    `/purchaseorder/tax/get?item_id=${params?.item_id}&totalAmountOfItem=${params?.purchase_rate}`
  );
};
